import { type PaymentDetails } from '@koala/sdk';
import { type IStoredValue } from '@/types/paymentInfo';

const paymentActions = {
  CREDIT_CARD_TRANSACTION_ID_GET: '/koala/payment/CREDIT_CARD_TRANSACTION_ID_GET',

  // Stored Value
  STORED_VALUE_TOGGLE: '/koala/payment/STORED_VALUE_TOGGLE',
  DIGITAL_WALLET_TOGGLE: '/koala/payment/DIGITAL_WALLET_TOGGLE',
  STORED_VALUE_CLEAR: '/koala/payment/STORED_VALUE_CLEAR',
  STORED_VALUE_SET: '/koala/payment/STORED_VALUE_SET',
  STORED_VALUE_ERROR: '/koala/payment/STORED_VALUE_ERROR',

  BILLING_ACCOUNT_SET: '/koala/payment/BILLING_ACCOUNT_SET',
  CREDIT_CARD_SET: '/koala/payment/CREDIT_CARD_SET',
  PAY_IN_STORE_SET: '/koala/payment/PAY_IN_STORE_SET',

  PAYMENT_TYPE_RESET: '/koala/payment/PAYMENT_TYPE_RESET',

  // Credit card exchanges
  creditCardTransactionIdGet: (creditCard: PaymentDetails) => ({
    type: paymentActions.CREDIT_CARD_TRANSACTION_ID_GET,
    creditCard,
  }),

  // Set payment types
  billingAccountSet: (savedAccountId: string) => ({
    type: paymentActions.BILLING_ACCOUNT_SET,
    savedAccountId,
  }),
  creditCardSet: (creditCard: PaymentDetails) => ({
    type: paymentActions.CREDIT_CARD_SET,
    creditCard,
  }),
  payInStoreSet: () => ({
    type: paymentActions.PAY_IN_STORE_SET,
  }),
  storedValueToggle: () => ({
    type: paymentActions.STORED_VALUE_TOGGLE,
  }),
  digitalWalletToggle: (selectedDigitalWallet: string) => ({
    type: paymentActions.DIGITAL_WALLET_TOGGLE,
    selectedDigitalWallet: selectedDigitalWallet,
  }),

  // Reset paymentType
  paymentTypeReset: () => ({
    type: paymentActions.PAYMENT_TYPE_RESET,
  }),

  // Stored Value
  storedValueSet: (storedValue: IStoredValue) => ({
    type: paymentActions.STORED_VALUE_SET,
    storedValue,
  }),
  storedValueError: (message: string) => ({
    type: paymentActions.STORED_VALUE_ERROR,
    message,
  }),
  storedValueClear: () => ({
    type: paymentActions.STORED_VALUE_CLEAR,
  }),
};

export default paymentActions;
