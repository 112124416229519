import { PAYMENT_TYPES } from '@koala/sdk';
import { type AnyAction } from 'redux';
import orderStatusActions from '../orderStatus/actions';
import actions from './actions';
import { type IPaymentState } from '@/types/paymentInfo';

export const initialState: IPaymentState = {
  storedValueError: '',
  storedValue: null,
  paymentType: null,
};

export const payment = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.BILLING_ACCOUNT_SET:
      return Object.assign({}, state, {
        paymentType: {
          type: PAYMENT_TYPES.BILLING_ACCOUNT,
          saved_account_id: action.savedAccountId,
        },
      });
    case actions.CREDIT_CARD_SET:
      return Object.assign({}, state, {
        paymentType: {
          ...action.creditCard,
        },
      });
    case actions.PAY_IN_STORE_SET:
      return Object.assign({}, state, {
        paymentType: {
          type: PAYMENT_TYPES.CASH,
        },
      });
    case actions.STORED_VALUE_TOGGLE:
      return Object.assign({}, state, {
        paymentType: {
          type: PAYMENT_TYPES.STORED_VALUE,
        },
      });
    case actions.DIGITAL_WALLET_TOGGLE:
      return Object.assign({}, state, {
        paymentType: {
          type: PAYMENT_TYPES.DIGITAL_WALLET,
          selectedDigitalWallet: action.selectedDigitalWallet,
        },
      });
    case actions.PAYMENT_TYPE_RESET:
      return Object.assign({}, state, {
        paymentType: initialState.paymentType,
      });
    case actions.STORED_VALUE_SET:
      return Object.assign({}, state, {
        storedValue: action.storedValue,
        storedValueError: initialState.storedValueError,
      });
    case actions.STORED_VALUE_ERROR:
      return Object.assign({}, state, {
        storedValue: initialState.storedValue,
        storedValueError: action.message,
      });
    case actions.STORED_VALUE_CLEAR:
    case orderStatusActions.STORED_VALUE_POST:
      return Object.assign({}, state, {
        storedValue: initialState.storedValue,
        storedValueError: initialState.storedValueError,
      });
    case orderStatusActions.INITIALIZE_ORDER:
    case orderStatusActions.DESTROY_ORDER:
      return initialState;
    default:
      return state;
  }
};
